const state = {
    errors: [],
    alert: {}
};

const getters = {
    getErrors: state => state.errors,
    getAlert: state => state.alert
};

const mutations = {
    ERROR: (state, payload) => {
        state.errors.push(payload);
    },
    ALERT: (state, payload) => {
        state.alert = payload;
    }
};

const actions = {
    error: (context, payload) => {
        context.commit("ERROR", payload);
        if (payload.status !== 403 && !payload.validation) {
            context.commit("ALERT", payload);
        }
        if (process.env.VUE_APP_MODE !== "production") {
            console.error(payload.heading + " -> " + payload.text);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
