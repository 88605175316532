/* eslint no-prototype-builtins: off */
import PersonsApi from "@/api/PersonsApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false
    }
};

const getters = {
    getData: state => state.data,
    getDataById: state => id => {
        return state.data.filter(item => item.id == id);
    },
    getSelectData: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.name
            };
        });
    },
    getName: (state, getters) => id => {
        const data = getters.getDataById(id);
        if (data.length > 0) {
            return data[0].name;
        } else {
            return "";
        }
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_DATA: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_DATA: (state, payload) => {
        const data = [...state.data];

        data.push(payload);
        state.data = data;
    },
    EDIT_DATA: (state, payload) => {
        const mutationIndex = state.data.findIndex(
            item => item.id == payload.id
        );
        const data = [...state.data];
        if (mutationIndex != -1) {
            data[mutationIndex] = payload;
            state.data = data;
        }
    },
    DELETE_DATA: (state, payload) => {
        var data = [...state.data];
        data = data.filter(item => item.id != payload);
        state.data = data;
    }
};

const actions = {
    fetchData: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const filter = payload && payload.filter ? payload.filter : null;

        const response = await PersonsApi.get(filter);
        if (response.ok) {
            context.commit("SET_DATA", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    exportExcel: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await PersonsApi.export(
            payload.format,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    exportPDF: async (context, payload) => {
        context.commit("BUSY", { pdf: true });
        const response = await PersonsApi.exportPDF(
            payload.orientation,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { pdf: false });
    },
    createData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await PersonsApi.post(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = { modification: modification, answer: true };
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    createPendingData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await PersonsApi.postPending(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = { modification: modification, answer: true };
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    editData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await PersonsApi.put(payload.id, payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_DATA", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await PersonsApi.delete(payload.id);
        if (response.ok) {
            context.commit("DELETE_DATA", payload.id);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteMultipleData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        const promises = [];
        for (const id of payload) {
            promises.push(PersonsApi.delete(id));
        }

        const responseAll = await Promise.all(promises);
        const failedResponses = [];
        for (const response of responseAll) {
            if (!response.ok) {
                failedResponses.push(response);
            } else {
                console.log("delete: ", response.json.data.id);
                context.commit("DELETE_DATA", response.json.data.id);
            }
        }
        context.commit("BUSY", { mutation: false });
        return !(failedResponses.length > 0);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
