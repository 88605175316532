<template>
    <b-modal
        id="changelogModal"
        :visible="visible"
        title="Versionshinweise"
        size="lg"
        ok-only
        scrollable
        @ok="setVersion"
        @hide="setVersion"
    >
        <Changelog />
    </b-modal>
</template>

<script>
import Changelog from "@/components/Changelog/Changelog";

export default {
    name: "ChangelogModal",
    components: {
        Changelog
    },
    props: {
        visible: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            triggered: false
        };
    },
    methods: {
        setVersion() {
            if (!this.triggered) {
                this.triggered = true;
                console.log(process.env.VUE_APP_VERSION);
                this.$store.dispatch(
                    "version/version",
                    process.env.VUE_APP_VERSION
                );
            }
        }
    }
};
</script>
