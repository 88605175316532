export default {
    methods: {
        makeToast(
            title,
            message,
            variant = "success",
            toaster = "b-toaster-top-right"
        ) {
            message = message ?? title;
            console.log(message);
            this.$bvToast.toast(message, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true,
                solid: true,
                toaster: toaster,
                variant: variant
            });
        }
    }
};
