<template>
    <b-navbar toggleable="lg" type="light" variant="light" sticky>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-navbar-brand tag="h1" href="#" :to="'/'">
            <img
                src="@/assets/images/logo_height.png"
                width="40"
                class="d-inline-block mr-2"
            />
            {{ title }}
        </b-navbar-brand>

        <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav v-if="auth.isAuthentificated">
                <b-nav-item :to="'/'">Übersicht</b-nav-item>
                <b-nav-item-dropdown
                    v-for="(value, key) in menu"
                    :key="key"
                    :text="key"
                >
                    <template v-for="item in value">
                        <template v-if="item.show !== false">
                            <b-dropdown-item
                                :key="item._links.self.href"
                                :to="item.slug"
                                >{{ item.title }}</b-dropdown-item
                            >
                        </template>
                    </template>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav v-else>
                <b-nav-item :to="'/login'">Anmeldung</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right v-if="auth.isAuthentificated">
                    <template v-slot:button-content>
                        <b-button size="sm">
                            <font-awesome-icon
                                icon="user"
                                class="mr-1"
                            />Benutzer*in
                        </b-button>
                    </template>
                    <b-dropdown-header>Angemeldet als</b-dropdown-header>
                    <b-dropdown-item disabled>{{
                        auth.username
                    }}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-header>Version</b-dropdown-header>
                    <b-dropdown-item disabled>{{ version }}</b-dropdown-item>
                    <b-dropdown-item to="/changelog"
                        >Versionshinweis</b-dropdown-item
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-header>Umgebung</b-dropdown-header>
                    <b-dropdown-item disabled>{{ env }}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item :href="urls.manual"
                        >Handbuch</b-dropdown-item
                    >
                    <b-dropdown-item :href="urls.docs"
                        >Dokumentation</b-dropdown-item
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item to="/settings"
                        >Einstellungen</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-on:click="logout"
                        href="#"
                        class="text-danger"
                        >Abmelden</b-dropdown-item
                    >
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Navbar",
    computed: {
        ...mapGetters({
            auth: "auth/getAuth",
            menu: "menu/getMenu",
            urls: "env/getUrls"
        }),
        title() {
            return process.env.VUE_APP_TITLE;
        },
        version() {
            return process.env.VUE_APP_VERSION;
        },
        env() {
            return process.env.VUE_APP_MODE;
        }
    },
    methods: {
        logout: function() {
            this.$store.dispatch("auth/logout").then(() => {
                this.$router.push("/login");
            });
        }
    }
};
</script>
