/* eslint no-prototype-builtins: off */
import MitarbeiterApi from "@/api/MitarbeiterApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false
    }
};

const getters = {
    getMitarbeiter: state => state.data,
    getBusy: state => state.busy
};

const mutations = {
    SET_MITARBEITER: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_MITARBEITER: (state, payload) => {
        const data = [...state.data];

        data.push(payload);
        state.data = data;
    },
    EDIT_MITARBEITER: (state, payload) => {
        const mutationIndex = state.data.findIndex(
            item => item.id == payload.id
        );
        const data = [...state.data];
        if (mutationIndex != -1) {
            data[mutationIndex] = payload;
            state.data = data;
        }
    },
    DELETE_MITARBEITER: (state, payload) => {
        var data = [...state.data];
        data = data.filter(item => item.id != payload);
        state.data = data;
    }
};

const actions = {
    fetchMitarbeiter: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await MitarbeiterApi.get(payload.filter);
        if (response.ok) {
            context.commit("SET_MITARBEITER", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    exportExcel: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await MitarbeiterApi.export(
            payload.format,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    exportPDF: async (context, payload) => {
        context.commit("BUSY", { pdf: true });
        const response = await MitarbeiterApi.exportPDF(
            payload.orientation,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { pdf: false });
    },
    createMitarbeiter: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await MitarbeiterApi.post(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_MITARBEITER", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    editMitarbeiter: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await MitarbeiterApi.put(payload.id, payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_MITARBEITER", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteMitarbeiter: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await MitarbeiterApi.delete(payload.id);
        if (response.ok) {
            context.commit("DELETE_MITARBEITER", payload.id);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteMultipleMitarbeiter: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        const promises = [];
        for (const id of payload) {
            promises.push(MitarbeiterApi.delete(id));
        }

        const responseAll = await Promise.all(promises);
        const failedResponses = [];
        for (const response of responseAll) {
            if (!response.ok) {
                failedResponses.push(response);
            } else {
                console.log("delete: ", response.json.data.id);
                context.commit("DELETE_MITARBEITER", response.json.data.id);
            }
        }
        context.commit("BUSY", { mutation: false });
        return !(failedResponses.length > 0);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
