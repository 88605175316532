/* eslint no-prototype-builtins: off */
import ExamsApi from "@/api/ExamsApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false
    }
};

const getters = {
    getData: state => state.data,
    getDataById: state => id => {
        return state.data.filter(item => item.id == id);
    },
    getSelectData: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.name
            };
        });
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_DATA: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_DATA: (state, payload) => {
        const data = [...state.data];

        data.push(payload);
        state.data = data;
    },
    EDIT_DATA: (state, payload) => {
        const mutationIndex = state.data.findIndex(
            item => item.id == payload.id
        );
        const data = [...state.data];
        if (mutationIndex != -1) {
            data[mutationIndex] = payload;
            state.data = data;
        }
    },
    DELETE_DATA: (state, payload) => {
        var data = [...state.data];
        data = data.filter(item => item.persons_id != payload);
        state.data = data;
    }
};

const actions = {
    fetchData: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await ExamsApi.getPersons(payload.id, payload.filter);
        if (response.ok) {
            context.commit(
                "rights/SET_RIGHTS",
                {
                    key: "exams-persons",
                    rights: response.json._embedded.rights
                },
                { root: true }
            );
            context.commit("SET_DATA", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    importCambridge: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        var answer = false;
        const response = await ExamsApi.importPersonsCambridge(
            payload.id,
            payload.file
        );
        if (response.ok) {
            answer = true;
        }
        context.commit("BUSY", { excel: false });
        return answer;
    },
    importCambridgePending: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        var answer = false;
        const response = await ExamsApi.importPersonsCambridgePending(
            payload.id,
            payload.file
        );
        if (response.ok) {
            answer = true;
        }
        context.commit("BUSY", { excel: false });
        return answer;
    },
    exportExcel: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await ExamsApi.exportPersons(
            payload.id,
            payload.format,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    exportCambridge: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await ExamsApi.exportPersonsCambridge(
            payload.id,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    createData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await ExamsApi.createPerson(
            payload.exam_id,
            payload.person_id,
            payload.type,
            payload.confirm_deadline
        );
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = modification;
        } else {
            answer = response.json;
        }
        context.commit("BUSY", { mutation: false });
        return { ok: response.ok, answer: answer };
    },
    createPendingData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await ExamsApi.createPendingPerson(
            payload.exam_id,
            payload.person_id,
            payload.type,
            payload.confirm_deadline
        );
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = modification;
        } else {
            answer = response.json;
        }
        context.commit("BUSY", { mutation: false });
        return { ok: response.ok, answer: answer };
    },
    confirmPendingData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await ExamsApi.confirmPendingPerson(
            payload.exam_id,
            payload.persons_id
        );
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_DATA", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    editData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await ExamsApi.editPerson(
            payload.exam_id,
            payload.id,
            payload
        );
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_DATA", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await ExamsApi.deletePerson(
            payload.event_id,
            payload.person_id
        );
        if (response.ok) {
            context.commit("DELETE_DATA", response.json.data.id);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
