import AuthApi from "./AuthApi";
import ChangelogApi from "./ChangelogApi";
import EventsApi from "./EventsApi";
import ExamsApi from "./ExamsApi";
import FileApi from "./FileApi";
import FormApi from "./FormApi";
import LabelApi from "./LabelApi";
import LocationsApi from "./LocationsApi";
import MitarbeiterApi from "./MitarbeiterApi";
import PersonsApi from "./PersonsApi";
import QRCodeApi from "./QRCodeApi";
import UserApi from "./UserApi";
import VHSApi from "./VHSApi";

const repositories = {
    auth: AuthApi,
    changelog: ChangelogApi,
    mitarbeiter: MitarbeiterApi,
    vhs: VHSApi,
    forms: FormApi,
    qr: QRCodeApi,
    user: UserApi,
    events: EventsApi,
    locations: LocationsApi,
    persons: PersonsApi,
    file: FileApi,
    label: LabelApi,
    exams: ExamsApi
};

export default {
    get: name => repositories[name]
};
