/* eslint no-prototype-builtins: off */

const state = {
    data: {}
};

const getters = {
    getTableFields: state => key => {
        if (state.data[key]) {
            return state.data[key];
        }
        return [];
    }
};

const mutations = {
    SET_TABLE_FIELDS: (state, payload) => {
        if (!state.data[payload.key]) {
            state.data[payload.key] = [];
        }
        state.data[payload.key] = payload.value;
    }
};

const actions = {
    setTableFields: async (context, payload) => {
        context.commit("SET_TABLE_FIELDS", {
            key: payload.key,
            value: payload.value
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
