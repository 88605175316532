/* eslint no-prototype-builtins: off */
import UserApi from "@/api/UserApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false
    }
};

const getters = {
    getUserGroups: state => state.data,
    getUserGroup: state => id => {
        const form = state.data.filter(item => item.id == id);
        return form[0];
    },
    getUserGroupName: (state, getters) => id => {
        const form = getters.getUserGroup(id);
        if (form) {
            return form.nicename;
        } else {
            return "";
        }
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_GROUPS: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    }
};

const actions = {
    fetchGroups: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await UserApi.getGroups();
        if (response.ok) {
            context.commit("SET_GROUPS", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
