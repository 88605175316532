import AuthApi from "@/api/AuthApi";

const state = {
    isAuthentificated: false,
    username: "public",
    password: "public",
    userId: null,
    groupId: null,
    groupName: "",
    locations: []
};

const getters = {
    getAuth: state => state,
    isLoggedIn: state => state.isAuthentificated
};

const mutations = {
    AUTH: (state, payload) => {
        var auth = state;
        auth.isAuthentificated = payload.isAuthentificated;
        auth.username = payload.username;
        auth.password = payload.password;
        auth.userId = payload.userId;
        auth.groupId = payload.groupId;
        auth.groupName = payload.groupName;
        auth.locations = payload.locations;
    },
    LOGOUT: (state, payload) => {
        state = Object.assign(state, {
            isAuthentificated: false,
            username: "public",
            password: "public",
            userId: null,
            groupId: null,
            groupName: "",
            locations: []
        });
    }
};

const actions = {
    auth: async ({ commit, dispatch }, payload) => {
        var username = payload.username;
        var password = payload.password;

        commit("AUTH", {
            isAuthentificated: false,
            username: username,
            password: password
        });

        const response = await AuthApi.get();
        var isAuthentificated = false;
        var userId = null;
        var groupId = null;
        var groupName = "";
        var locations = [];
        if (response.status === 200) {
            isAuthentificated = true;
            userId = response.json.data.user_id;
            if (response.json.data.user_data) {
                groupId = response.json.data.user_data.group_id;
                groupName = response.json.data.user_data.group_name;
                locations = response.json.data.user_data.locations;
            }
        } else {
            isAuthentificated = false;
            username = "public";
            password = "public";
            userId = null;
            groupId = null;
            groupName = "";
            locations = [];
        }

        commit("AUTH", {
            isAuthentificated: isAuthentificated,
            username: username,
            password: password,
            userId: userId,
            groupId: groupId,
            groupName: groupName,
            locations: locations
        });

        if (!isAuthentificated) {
            throw new Error("Wrong login credentials");
        }
        //dispatch("menu", response);
    },
    logout: (context, payload) => {
        context.commit("menu/CLEAN", null, { root: true });
        context.commit("LOGOUT", payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
