/* eslint no-prototype-builtins: off */

const state = {
    menu: {},
    currentPage: ""
};

const getters = {
    getMenu: state => state.menu,
    getCurrentPage: state => state.currentPage
};

const mutations = {
    MENU: (state, payload) => {
        state.menu = payload;
    },
    CURRENT_PAGE: (state, payload) => {
        state.currentPage = payload;
    },
    CLEAN: (state, payload) => {
        state = Object.assign(state, {
            menu: {},
            currentPage: ""
        });
    }
};

const actions = {
    menu: async (context, payload) => {
        if (payload.ok) {
            var json = payload.json;
            if (json.hasOwnProperty("_embedded")) {
                if (json._embedded.hasOwnProperty("menu")) {
                    var menuArr = {};
                    json._embedded.menu.forEach(element => {
                        if (typeof element.group === "undefined") {
                            return;
                        }
                        if (!menuArr[element.group]) {
                            menuArr[element.group] = [];
                        }
                        menuArr[element.group].push(element);
                    });
                    context.commit("MENU", menuArr);
                }
            }
        }
    },
    currentPage: (context, payload) => {
        var path = payload.path;
        context.commit("CURRENT_PAGE", path);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
