import Api from "./Api";

const endpoint = "/exams";
export default {
    get(filter = null) {
        return Api.fetchData(endpoint, Api.handleDefaultParams({ filter }));
    },
    getById(id) {
        return Api.fetchData(endpoint + "/" + id);
    },
    exportPersons(id, format, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/" + id + "/persons/export",
            { selected },
            Api.handleDefaultParams({ format, filter, fields })
        );
    },
    exportPersonsCambridge(id, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/" + id + "/persons/export/cambridge",
            { selected },
            Api.handleDefaultParams({ filter, fields })
        );
    },
    importPersonsCambridge(id, importFile) {
        return Api.uploadFile(
            endpoint + "/" + id + "/persons/import/cambridge",
            importFile
        );
    },
    importPersonsCambridgePending(id, importFile) {
        return Api.uploadFile(
            endpoint + "/" + id + "/persons/import/cambridge/pending",
            importFile
        );
    },
    post(data) {
        return Api.postData(endpoint, data);
    },
    postPending(data) {
        return Api.postData(endpoint + "/pending", data);
    },
    put(id, data) {
        return Api.putData(endpoint + "/" + id, data);
    },
    duplicate(id) {
        return Api.putData(endpoint + "/" + id + "/duplicate");
    },
    delete(id) {
        return Api.deleteData(endpoint + "/" + id);
    },
    confirmPendingExam(id) {
        return Api.putData(endpoint + "/" + id + "/confirm");
    },
    getPersons(id, filter) {
        return Api.fetchData(
            endpoint + "/" + id + "/persons",
            Api.handleDefaultParams({ filter })
        );
    },
    getAuditors(id) {
        return Api.fetchData(endpoint + "/" + id + "/auditors");
    },
    createPerson(id, persons_id, type = null, confirm_deadline = false) {
        return Api.postData(endpoint + "/" + id + "/persons", {
            persons_id,
            type,
            confirm_deadline
        });
    },
    createPendingPerson(id, person_id, type = null, confirm_deadline = false) {
        return Api.postData(endpoint + "/" + id + "/persons/pending", {
            person_id,
            type,
            confirm_deadline
        });
    },
    editPerson(id, relation_id, data) {
        return Api.putData(
            endpoint + "/" + id + "/persons/" + relation_id,
            data
        );
    },
    deletePerson(id, person_id) {
        return Api.deleteData(endpoint + "/" + id + "/persons/" + person_id);
    },
    confirmPendingPerson(id, person_id) {
        return Api.putData(
            endpoint + "/" + id + "/persons/" + person_id + "/confirm"
        );
    },
    getTypes() {
        return Api.fetchData(endpoint + "/types");
    },
    getCambridgePrefixes() {
        return Api.fetchData(endpoint + "/cambridge/prefix");
    },
    getStatus() {
        return Api.fetchData(endpoint + "/status");
    },
    getDeadlines(filter = null) {
        return Api.fetchData(
            endpoint + "/deadlines",
            Api.handleDefaultParams({ filter })
        );
    },
    getDeadlinesForDate(date, filter = null) {
        return Api.putData(
            endpoint + "/deadlines/current",
            { date },
            Api.handleDefaultParams({ filter })
        );
    },
    getDeadlinesForExam(exam_id) {
        return Api.fetchData(endpoint + "/deadlines/exam/" + exam_id);
    },
    createDeadline(data) {
        return Api.postData(endpoint + "/deadlines", data);
    },
    editDeadline(id, data) {
        return Api.putData(endpoint + "/deadlines/" + id, data);
    },
    deleteDeadline(id) {
        return Api.deleteData(endpoint + "/deadlines" + id);
    },
    getCambridgeSubcentres() {
        return Api.fetchData(endpoint + "/cambridge/subcentres");
    },
    getCambridgePrepcentres(filter = null) {
        const params = Api.handleDefaultParams(filter);
        return Api.fetchData(endpoint + "/cambridge/prepcentres", params);
    }
};
