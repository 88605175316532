/* eslint no-prototype-builtins: off */

const state = {
    data: [
        {
            value: "phone",
            text: "Festnetz"
        },
        {
            value: "mobile",
            text: "Mobil"
        },
        {
            value: "email",
            text: "E-Mail"
        }
    ]
};

const getters = {
    getData: state => state.data,
    getSelectData: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.name
            };
        });
    }
};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
