//

/* eslint no-prototype-builtins: off */
import FormApi from "@/api/FormApi";

const state = {
    data: [],
    busy: {
        data: false
    }
};

const getters = {
    getTags: state => state.data,
    getTagById: state => id => {
        return state.data.filter(item => item.id == id);
    },
    getTagsForSelect: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.name
            };
        });
    },
    getTagName: (state, getters) => id => {
        const data = getters.getTagById(id);
        if (data.length > 0) {
            return data[0].name;
        } else {
            return "";
        }
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_TAGS: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        state.busy = Object.assign(state.busy, payload);
    }
};

const actions = {
    fetchTags: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await FormApi.getTags();
        if (response.ok) {
            context.commit("SET_TAGS", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
