/* eslint no-prototype-builtins: off */

const state = {
    data: {}
};

const getters = {
    getFilter: state => key => {
        if (state.data[key]) {
            return state.data[key];
        }
        return {};
    }
};

const mutations = {
    SET_FILTER: (state, payload) => {
        if (state.data[payload.key]) {
            Object.assign(state.data[payload.key], payload.value);
        } else {
            state.data[payload.key] = payload.value;
        }
    }
};

const actions = {
    setFilter: async (context, payload) => {
        const filteredValue = Object.keys(payload.value)
            .filter(key => key !== "pattern")
            .reduce((obj, key) => {
                obj[key] = payload.value[key];
                return obj;
            }, {});
        context.commit("SET_FILTER", {
            key: payload.key,
            value: filteredValue
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
