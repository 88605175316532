import Api from "./Api";

const endpoint = "/events";
export default {
    get(filter = null) {
        return Api.fetchData(endpoint, Api.handleDefaultParams({ filter }));
    },
    getById(id) {
        return Api.fetchData(endpoint + "/" + id);
    },
    exportParticipants(id, format, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/" + id + "/participants/export",
            { selected },
            Api.handleDefaultParams({ format, filter, fields })
        );
    },
    exportParticipantsPDF(
        id,
        orientation,
        filter = null,
        fields = [],
        selected = []
    ) {
        return Api.putData(
            endpoint + "/" + id + "/participants/pdf",
            { selected },
            Api.handleDefaultParams({ orientation, filter, fields })
        );
    },
    post(data) {
        return Api.postData(endpoint, data);
    },
    put(id, data) {
        return Api.putData(endpoint + "/" + id, data);
    },
    duplicate(id) {
        return Api.putData(endpoint + "/" + id + "/duplicate");
    },
    delete(id) {
        return Api.deleteData(endpoint + "/" + id);
    },
    getTypes() {
        return Api.fetchData(endpoint + "/types");
    },
    getSections() {
        return Api.fetchData(endpoint + "/sections");
    },
    getParticipants(id) {
        return Api.fetchData(endpoint + "/" + id + "/participants");
    },
    createParticipant(id, person_id, form_answer_id = null) {
        return Api.postData(endpoint + "/" + id + "/participants", {
            person_id,
            form_answer_id
        });
    },
    deleteParticipant(id, person_id) {
        return Api.deleteData(
            endpoint + "/" + id + "/participants/" + person_id
        );
    },
    getForms(id) {
        return Api.fetchData(endpoint + "/" + id + "/forms");
    },
    createForms(id, form_id) {
        return Api.postData(endpoint + "/" + id + "/forms", { form_id });
    },
    deleteForms(id, form_id) {
        return Api.deleteData(endpoint + "/" + id + "/forms/" + form_id);
    },
    getFiles(id) {
        return Api.fetchData(endpoint + "/" + id + "/files");
    },
    createFile(id, file_id, notice) {
        return Api.postData(endpoint + "/" + id + "/files", {
            file_id,
            notice
        });
    },
    editFileRelation(id, file_id, notice) {
        return Api.putData(endpoint + "/" + id + "/files/" + file_id, {
            notice
        });
    },
    deleteFile(id, file_id) {
        return Api.deleteData(endpoint + "/" + id + "/files/" + file_id);
    },
    getProtocol(exam_id) {
        return Api.fetchData(endpoint + "/" + exam_id + "/protocol");
    },
    createProtocolEntry(exam_id, data) {
        return Api.postData(endpoint + "/" + exam_id + "/protocol", data);
    },
    appendProtocolEntry(exam_id, protocol_id, data) {
        return Api.postData(
            endpoint + "/" + exam_id + "/protocol/" + protocol_id,
            data
        );
    },
    getTags() {
        return Api.fetchData(endpoint + "/tags");
    }
};
