//

/* eslint no-prototype-builtins: off */
import ExamsApi from "@/api/ExamsApi";

const state = {
    data: [],
    busy: {
        data: false
    }
};

const getters = {
    getTypes: state => state.data,
    getTypeByKey: state => key => {
        return state.data.filter(item => item.key == key);
    },
    getSelectData: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.title
            };
        });
    },
    getTypeName: (state, getters) => key => {
        const data = getters.getTypeByKey(key);
        if (data.length > 0) {
            return data[0].value;
        } else {
            return "";
        }
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_DATA: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        state.busy = Object.assign(state.busy, payload);
    }
};

const actions = {
    fetchData: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await ExamsApi.getStatus();
        if (response.ok) {
            context.commit("SET_DATA", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
