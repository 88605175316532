<template>
    <section class="changelog">
        <template v-if="changes.html">
            <div v-html="changes.html" />
        </template>
        <template v-else>
            <CenteredMessage :spinner="busy">
                <template v-if="error">
                    Die neusten Änderungen konnten nicht geladen werden
                    <div class="my-1">
                        <b-button :size="size" @click="fetch"
                            >Erneut versuchen</b-button
                        >
                    </div>
                </template>
                <template v-else>
                    Lädt Neuerungsansicht...
                </template>
            </CenteredMessage>
        </template>
    </section>
</template>

<script>
import Size from "@/mixins/Size/Size";
import CenteredMessage from "@/components/CenteredMessage/CenteredMessage";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Changelog",
    components: {
        CenteredMessage
    },
    mixins: [Size],
    computed: {
        ...mapGetters({
            changes: "changelog/getChanges",
            busy: "changelog/getBusy",
            error: "changelog/getError"
        })
    },
    created() {
        this.fetch();
    },
    methods: {
        ...mapActions({
            fetch: "changelog/fetchChangelog"
        })
    }
};
</script>
