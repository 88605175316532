/* eslint no-prototype-builtins: off */
import ChangelogApi from "@/api/ChangelogApi";
import showdown from "showdown";

const converter = new showdown.Converter();

const state = {
    changes: {
        raw: null,
        html: null
    },
    busy: false,
    error: false
};

const getters = {
    getChanges: state => state.changes,
    getBusy: state => state.busy,
    getError: state => state.error
};

const mutations = {
    CHANGES: (state, payload) => {
        state.changes = payload;
    },
    BUSY: (state, payload) => {
        state.busy = payload;
    },
    ERROR: (state, payload) => {
        state.error = payload;
    }
};

const actions = {
    fetchChangelog: async (context, payload) => {
        context.commit("BUSY", true);
        context.commit("ERROR", false);

        const response = await ChangelogApi.get();
        if (response.ok) {
            const text = response.text;
            const html = converter.makeHtml(text);

            context.commit("CHANGES", {
                raw: text,
                html: html
            });
        } else {
            context.commit("ERROR", true);
        }
        context.commit("BUSY", false);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
