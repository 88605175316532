/* eslint no-prototype-builtins: off */

const state = {
    data: {}
};

const getters = {
    getSort: state => key => {
        if (state.data[key]) {
            return state.data[key];
        }
        return {};
    }
};

const mutations = {
    SET_SORT_BY: (state, payload) => {
        if (!state.data[payload.key]) {
            state.data[payload.key] = { sortBy: "id", sortDesc: false };
        }
        state.data[payload.key].sortBy = payload.sortBy;
    },
    SET_SORT_DESC: (state, payload) => {
        if (!state.data[payload.key]) {
            state.data[payload.key] = { sortBy: "id", sortDesc: false };
        }
        state.data[payload.key].sortDesc = payload.sortDesc;
    }
};

const actions = {
    setSortBy: async (context, payload) => {
        context.commit("SET_SORT_BY", {
            key: payload.key,
            sortBy: payload.sortBy
        });
    },
    setSortDesc: async (context, payload) => {
        context.commit("SET_SORT_DESC", {
            key: payload.key,
            sortDesc: payload.sortDesc
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
