<template>
    <article class="centered-message">
        <h5 class="title">
            <slot name="title"></slot>
        </h5>
        <Spinner v-if="spinner" class="spinner" small />
        <div class="body">
            <slot></slot>
        </div>
    </article>
</template>

<script>
import Spinner from "@/components/Spinner/Spinner";

import "./CenteredMessage.scss";

export default {
    name: "CenteredMessage",
    props: ["spinner"],
    components: {
        Spinner
    }
};
</script>