/* eslint no-prototype-builtins: off */
import FileApi from "@/api/FileApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    busy: {
        data: false,
        mutation: false
    }
};

const getters = {
    getData: state => state.data,
    getDataById: state => id => {
        return state.data.filter(item => item.id == id);
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_DATA: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_DATA: (state, payload) => {
        const data = [...state.data];

        data.push(payload);
        state.data = data;
    }
};

const actions = {
    fetchData: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const filter = payload && payload.filter ? payload.filter : null;

        const response = await FileApi.get(filter);
        if (response.ok) {
            context.commit("SET_DATA", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    createData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await FileApi.post(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = { modification: modification, answer: true };
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    downloadFile: async (context, payload) => {
        const response = await FileApi.getFile(payload.id, payload.params);
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
