/* eslint no-prototype-builtins: off */
import ExamsApi from "@/api/ExamsApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    dirty: false,
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false,
        linkGeneration: false
    }
};

const getters = {
    getData: state => state.data,
    getDataById: state => id => {
        const form = state.data.filter(item => item.id == id);
        return form[0];
    },
    getTitle: (state, getters) => id => {
        const form = getters.getDataById(id);
        if (form) {
            return form.title;
        } else {
            return "";
        }
    },
    getBusy: state => state.busy,
    getDirty: state => state.dirty
};

const mutations = {
    SET_DATA: (state, payload) => {
        state.data = payload;
    },
    SET_DIRTY: (state, payload) => {
        state.dirty = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_DATA: (state, payload) => {
        const data = [...state.data];
        data.push(payload);
        state.data = data;
    },
    EDIT_DATA: (state, payload) => {
        const mutationIndex = state.data.findIndex(
            item => item.id == payload.id
        );
        const data = [...state.data];
        if (mutationIndex != -1) {
            data[mutationIndex] = payload;
            state.data = data;
        }
    },
    DELETE_DATA: (state, payload) => {
        var data = [...state.data];
        data = data.filter(item => item.id != payload);
        state.data = data;
    }
};

const actions = {
    fetchData: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await ExamsApi.get(payload.filter);
        if (response.ok) {
            context.commit(
                "rights/SET_RIGHTS",
                {
                    key: "exams",
                    rights: response.json._embedded.rights
                },
                { root: true }
            );
            context.commit("SET_DATA", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    fetchDataByID: async (context, payload) => {
        context.commit("BUSY", { data: true });

        if (payload == "new") {
            context.commit("SET_DATA", [
                {
                    id: payload,
                    fields: [],
                    is_active: 0,
                    send_register_confirmation: 0,
                    send_register_return: 0
                }
            ]);
        } else {
            const response = await ExamsApi.getById(payload);
            if (response.ok) {
                context.commit(
                    "rights/SET_RIGHTS",
                    {
                        key: "exams",
                        rights: response.json._embedded.rights
                    },
                    { root: true }
                );
                context.commit("SET_DATA", [response.json.data] ?? []);
            }
        }
        context.commit("BUSY", { data: false });
    },
    exportExcel: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await ExamsApi.export(
            payload.format,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    exportPDF: async (context, payload) => {
        context.commit("BUSY", { pdf: true });
        const response = await ExamsApi.exportPDF(
            payload.orientation,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { pdf: false });
    },
    createData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await ExamsApi.post(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = modification;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    createPendingData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await ExamsApi.postPending(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = modification;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    editData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await ExamsApi.put(payload.id, payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_DATA", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    duplicateData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await ExamsApi.duplicate(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = modification;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await ExamsApi.delete(payload.id);
        if (response.ok) {
            context.commit("DELETE_DATA", payload.id);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteMultipleData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        const promises = [];
        for (const id of payload) {
            promises.push(ExamsApi.delete(id));
        }

        const responseAll = await Promise.all(promises);
        const failedResponses = [];
        for (const response of responseAll) {
            if (!response.ok) {
                failedResponses.push(response);
            } else {
                console.log("delete: ", response.json.data.id);
                context.commit("DELETE_DATA", response.json.data.id);
            }
        }
        context.commit("BUSY", { mutation: false });
        return !(failedResponses.length > 0);
    },
    confirmPendingData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await ExamsApi.confirmPendingExam(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_DATA", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
