import Api from "./Api";

const endpoint = "/files";
export default {
    get(filter = null) {
        return Api.fetchData(endpoint, Api.handleDefaultParams({ filter }));
    },
    getFile(id, params) {
        return Api.fetchData(
            endpoint + "/" + id,
            Api.handleDefaultParams(params),
            true,
            true
        );
    },
    post(file) {
        return Api.uploadFile(endpoint, file);
    }
};
