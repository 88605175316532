import Api from "./Api";

const endpoint = "/persons";
export default {
    get(filter = null) {
        return Api.fetchData(endpoint, Api.handleDefaultParams({ filter }));
    },
    export(format, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/export",
            { selected },
            Api.handleDefaultParams({ format, filter, fields })
        );
    },
    exportPDF(orientation, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/pdf",
            { selected },
            Api.handleDefaultParams({ orientation, filter, fields })
        );
    },
    post(data) {
        return Api.postData(endpoint, data);
    },
    postPending(data) {
        return Api.postData(endpoint + "/pending", data);
    },
    put(id, data) {
        return Api.putData(endpoint + "/" + id, data);
    },
    delete(id) {
        return Api.deleteData(endpoint + "/" + id);
    },
    duplicateDetection(filter, query, limit, page) {
        return Api.putData(
            endpoint + "/compare",
            query,
            Api.handleDefaultParams({ filter, limit, page })
        );
    }
};
