<template>
    <MainContainer>
        <div class="home">
            <InfoPage />
        </div>
    </MainContainer>
</template>

<script>
// @ is an alias to /src
import MainContainer from "@/components/MainContainer/MainContainer";
import InfoPage from "@/components/InfoPage/InfoPage";

export default {
    name: "Home",
    components: {
        MainContainer,
        InfoPage
    }
};
</script>
