<template>
    <b-spinner
        :class="'spinner custom-spinner ' + styling"
        :label="lLabel"
        :variant="lVariant"
        :small="lSmall"
    ></b-spinner>
</template>

<script>
import "./Spinner.scss";

export default {
    name: "Spinner",
    props: ["label", "variant", "small", "block"],
    computed: {
        lLabel() {
            return this.label || "Loading";
        },
        lVariant() {
            return this.variant || "secondary";
        },
        lSmall() {
            return typeof this.small !== "undefined" ? this.small : false;
        },
        styling() {
            return typeof this.block !== "undefined"
                ? "d-block mx-auto my-1"
                : "";
        }
    }
};
</script>
