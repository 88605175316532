import store from "../store";

const baseUrl = process.env.VUE_APP_API_URL;
const version = "v1";
const apiUrl = baseUrl + "/" + version;
var abortController = {};

export default {
    abort(abortKey = null) {
        if (abortKey) {
            if (abortController[abortKey]) {
                abortController[abortKey].abort();
                abortController[abortKey] = new AbortController();
            }
        } else {
            if (abortController) {
                Object.values(abortController).forEach(ac => ac.abort());
            }
        }
    },
    _headers() {
        const auth = store.getters["auth/getAuth"];

        var headers = new Headers();
        headers.append(
            "Authorization",
            "Basic " +
                Buffer.from(auth.username + ":" + auth.password).toString(
                    "base64"
                )
        );
        return headers;
    },

    _params(endpoint, params) {
        const url = new URL(apiUrl + endpoint);
        if (params) {
            Object.keys(params).forEach(key => {
                if (typeof params[key] !== "undefined") {
                    params[key] = Number.isInteger(params[key])
                        ? params[key].toString()
                        : params[key];
                    if (params[key] !== null && params[key].length > 0) {
                        url.searchParams.append(key, params[key]);
                    }
                }
            });
        }
        return url;
    },

    _options(method, body, abortable = false, abortKey = null) {
        var options = {
            method: method,
            headers: this._headers(),
            credentials: "include",
            mode: "cors"
        };
        if (abortable && abortKey && abortController[abortKey]) {
            options.signal = abortController[abortKey].signal;
        }
        if (body) {
            options.headers.append("Content-Type", "application/json");
            options.body = JSON.stringify(body);
        }
        return options;
    },
    _cleanContentType(contentType) {
        return contentType
            .replace(";charset=utf-8", "")
            .replace(";CHARSET=UTF-8", "")
            .replace(";charset=UTF-8", "")
            .replace("; charset=UTF-8", "")
            .trim();
    },
    async _compose(response) {
        var answer = {};
        if (typeof response !== "undefined") {
            answer.status = response.status;
            answer.statusText = response.statusText;
            answer.ok = response.ok;
            answer.contentType = this._cleanContentType(
                response.headers.get("content-type")
            );
        }
        switch (this._cleanContentType(response.headers.get("content-type"))) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel":
            case "application/vnd.oasis.opendocument.spreadsheet":
            case "text/csv":
            case "application/pdf":
            case "image/jpeg":
            case "image/png":
            case "video/mp4":
                try {
                    const disposition = response.headers.get(
                        "content-disposition"
                    );
                    answer.filename = disposition
                        ? disposition.split("filename=")[1]
                        : null;

                    const blob = await response.blob();
                    answer.blob = blob;
                } catch (error) {
                    console.log(error);
                    console.log(response);
                }
                break;
            case "text/plain":
            case "text/html":
            case "text/markdown":
                try {
                    const text = await response.text();
                    answer.text = text;
                } catch (error) {
                    console.log(error);
                }
                break;
            default:
                try {
                    const json = await response.json();
                    answer.json = json;
                } catch (error) {
                    console.log(error);
                }
        }
        return answer;
    },
    _menuHandling(answer) {
        if (answer.json) {
            store.dispatch("menu/menu", answer);
        }
    },
    _errorHandling(heading, text, status = null, validationErrors = null) {
        store.dispatch("error/error", {
            heading: heading,
            text: text,
            status: status,
            validation: validationErrors
        });
    },
    async _fetch(
        endpoint,
        method,
        params,
        data,
        abortable = false,
        abortKey = null,
        dismissErrors = false
    ) {
        try {
            const url = this._params(endpoint, params);
            const response = await fetch(
                url,
                this._options(method, data, abortable, abortKey)
            );
            const answer = await this._compose(response);
            if (!answer.ok && !dismissErrors) {
                var text = answer.json.error.description;
                this._errorHandling(
                    answer.status + " " + answer.statusText,
                    text,
                    answer.status,
                    answer.json.error.validation
                );
            }
            this._menuHandling(answer);
            return answer;
        } catch (error) {
            if (error.name !== "AbortError" && error.name !== "TypeError") {
                this._errorHandling("Request Error", error);
            }
            return {
                ok: false,
                statusText: error
            };
        }
    },
    handleDefaultParams(params = {}) {
        const newParams = {};
        if (typeof params.fields !== "undefined" && params.fields !== null) {
            newParams.fields = params.fields;
        }

        if (typeof params.filter !== "undefined" && params.filter !== null) {
            Object.keys(params.filter).forEach(key => {
                const element = String(params.filter[key]);
                if (
                    typeof element !== "undefined" &&
                    element !== null &&
                    element !== "" &&
                    element !== "null" &&
                    element.length > 0
                ) {
                    newParams["filter_" + key] = element;
                }
            });
        }
        delete params.fields;
        delete params.filter;
        Object.assign(newParams, params);
        return newParams;
    },
    async fetchData(
        endpoint,
        params,
        abortable = false,
        dismissErrors = false,
        abortKey = null
    ) {
        const answer = await this._fetch(
            endpoint,
            "get",
            params,
            null,
            abortable,
            abortKey,
            dismissErrors
        );
        return answer;
    },
    async putData(endpoint, body, params = null) {
        return await this._fetch(endpoint, "put", params, body);
    },
    async postData(endpoint, body) {
        return await this._fetch(endpoint, "post", null, body);
    },
    async deleteData(endpoint, params = null) {
        return await this._fetch(endpoint, "delete", params, null);
    },
    async uploadFile(endpoint, file) {
        var options = this._options("post", null);

        const data = new FormData();
        data.append("file", file, file.name);

        options.body = data;

        // TODO: Refactor
        try {
            const response = await fetch(apiUrl + endpoint, options);
            const answer = await this._compose(response);
            if (!answer.ok) {
                this._errorHandling(
                    answer.status + " " + answer.statusText,
                    answer.json.info + " - " + answer.json.message,
                    answer.status
                );
            }
            this._menuHandling(answer);
            return answer;
        } catch (error) {
            this._errorHandling("Request Error", error);
            return {
                ok: false,
                statusText: error
            };
        }
    }
};
