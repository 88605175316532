import router from "@/router";

const state = {
    currentVersion: ""
};

const getters = {
    getVersion: state => state.currentVersion
};

const mutations = {
    VERSION: (state, payload) => {
        state.currentVersion = payload;
    }
};

const actions = {
    version: (context, payload) => {
        const storedVersion = context.getters.currentVersion;
        console.log(payload);
        context.commit("VERSION", payload);
        if (storedVersion !== "") {
            context.commit("menu/CLEAN", null, { root: true });
            context.commit("settings/CLEAN", null, { root: true });
            // router.go();
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
