const state = {
    urls: {
        manual: process.env.VUE_APP_MANUAL_URL,
        docs: process.env.VUE_APP_DOCS_URL,
        apiDocs: process.env.VUE_APP_API_DOCS_URL,
        homepage: process.env.VUE_APP_HOMEPAGE_URL
    }
};

const getters = {
    getUrls: state => state.urls
};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
