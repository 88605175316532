/* eslint no-prototype-builtins: off */
import EventsApi from "@/api/EventsApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    dirty: false,
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false,
        linkGeneration: false
    }
};

const getters = {
    getEvents: state => state.data,
    getEvent: state => id => {
        const form = state.data.filter(item => item.id == id);
        return form[0];
    },
    getEventByToken: state => token => {
        const form = state.data.filter(item => item.token == token);
        return form[0];
    },
    getEventName: (state, getters) => id => {
        const form = getters.getEvent(id);
        if (form) {
            return form.title;
        } else {
            return "";
        }
    },
    getBusy: state => state.busy,
    getDirty: state => state.dirty
};

const mutations = {
    SET_EVENTS: (state, payload) => {
        state.data = payload;
    },
    SET_DIRTY: (state, payload) => {
        state.dirty = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_EVENT: (state, payload) => {
        const data = [...state.data];
        data.push(payload);
        state.data = data;
    },
    EDIT_EVENT: (state, payload) => {
        const mutationIndex = state.data.findIndex(
            item => item.id == payload.id
        );
        const data = [...state.data];
        if (mutationIndex != -1) {
            data[mutationIndex] = payload;
            state.data = data;
        }
    },
    DELETE_EVENT: (state, payload) => {
        var data = [...state.data];
        data = data.filter(item => item.id != payload);
        state.data = data;
    }
};

const actions = {
    fetchEvents: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await EventsApi.get(payload.filter);
        if (response.ok) {
            context.commit("SET_EVENTS", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    fetchEventByID: async (context, payload) => {
        context.commit("BUSY", { data: true });

        if (payload == "new") {
            context.commit("SET_EVENTS", [
                {
                    id: payload,
                    fields: [],
                    is_active: 0,
                    send_register_confirmation: 0,
                    send_register_return: 0
                }
            ]);
        } else {
            const response = await EventsApi.getById(payload);
            if (response.ok) {
                context.commit("SET_EVENTS", [response.json.data] ?? []);
            }
        }
        context.commit("BUSY", { data: false });
    },
    exportExcel: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await EventsApi.export(
            payload.format,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    exportPDF: async (context, payload) => {
        context.commit("BUSY", { pdf: true });
        const response = await EventsApi.exportPDF(
            payload.orientation,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { pdf: false });
    },
    createEvent: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await EventsApi.post(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_EVENT", modification);
            answer = modification;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    editEvent: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await EventsApi.put(payload.id, payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_EVENT", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    duplicateEvent: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await EventsApi.duplicate(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_EVENT", modification);
            answer = modification;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteEvent: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await EventsApi.delete(payload.id);
        if (response.ok) {
            context.commit("DELETE_EVENT", payload.id);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteMultipleEvents: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        const promises = [];
        for (const id of payload) {
            promises.push(EventsApi.delete(id));
        }

        const responseAll = await Promise.all(promises);
        const failedResponses = [];
        for (const response of responseAll) {
            if (!response.ok) {
                failedResponses.push(response);
            } else {
                console.log("delete: ", response.json.data.id);
                context.commit("DELETE_EVENT", response.json.data.id);
            }
        }
        context.commit("BUSY", { mutation: false });
        return !(failedResponses.length > 0);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
