import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import auth from "./modules/auth";
import menu from "./modules/menu";
import settings from "./modules/settings";
import version from "./modules/version";
import changelog from "./modules/changelog";
import mitarbeiter from "./modules/mitarbeiter";
import vhs from "./modules/vhs";
import forms from "./modules/forms";
import env from "./modules/env";
import error from "./modules/error";
import formsAnswers from "./modules/forms-answers";
import formsFields from "./modules/forms-fields";
import formsTags from "./modules/forms-tags";
import user from "./modules/user";
import userGroups from "./modules/user-groups";
import events from "./modules/events";
import eventsTypes from "./modules/events-types";
import eventsSections from "./modules/events-sections";
import locations from "./modules/locations";
import persons from "./modules/persons";
import eventsParticipants from "./modules/events-participants";
import eventsForms from "./modules/events-forms";
import eventsFiles from "./modules/events-files";
import files from "./modules/files";
import personsDuplicate from "./modules/persons-duplicate";
import label from "./modules/label";
import contactTypes from "./modules/persons-contact-types";
import exams from "./modules/exams";
import examsPersons from "./modules/exams-persons";
import examsTypes from "./modules/exams-types";
import examsDeadlines from "./modules/exams-deadlines";
import examsStatus from "./modules/exams-status";
import filter from "./modules/filter";
import rights from "./modules/rights";
import examsCambridgePrefix from "./modules/exam-cambridge-prefix";
import examsAuditors from "./modules/exams-auditors";
import eventsProtocol from "./modules/events-protocol";
import examsCambridgeSubcentre from "./modules/exams-cambridge-subcentre";
import examsCambridgePrepcentre from "./modules/exams-cambridge-prepcentre";
import sort from "./modules/sort";
import tableFields from "./modules/table-fields";
import eventsTags from "./modules/events-tags";

Vue.use(Vuex);

const state = {};

const getters = {};

const mutations = {};

const actions = {};

const vuexPersist = new VuexPersist({
    key: "vhs-verwaltung",
    storage: localStorage,
    reducer: state => ({
        auth: state.auth,
        version: state.version,
        settings: state.settings,
        menu: state.menu,
        filter: state.filter,
        sort: state.sort,
        tableFields: state.tableFields
    })
});

export default new Vuex.Store({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        auth,
        menu,
        settings,
        version,
        changelog,
        mitarbeiter,
        vhs,
        forms,
        env,
        error,
        formsAnswers,
        formsFields,
        formsTags,
        user,
        userGroups,
        events,
        eventsTypes,
        eventsSections,
        locations,
        persons,
        eventsParticipants,
        eventsForms,
        eventsFiles,
        files,
        personsDuplicate,
        label,
        contactTypes,
        exams,
        examsPersons,
        examsTypes,
        examsDeadlines,
        examsStatus,
        filter,
        rights,
        examsCambridgePrefix,
        examsAuditors,
        eventsProtocol,
        examsCambridgeSubcentre,
        examsCambridgePrepcentre,
        sort,
        tableFields,
        eventsTags
    },
    plugins: [vuexPersist.plugin]
});
