import Api from "./Api";

const endpoint = "/forms";
export default {
    get(filter = null) {
        return Api.fetchData(endpoint, Api.handleDefaultParams({ filter }));
    },
    getById(id) {
        return Api.fetchData(endpoint + "/" + id);
    },
    getPublic(token) {
        return Api.fetchData("/public/forms/" + token);
    },
    export(format, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/export",
            { selected },
            Api.handleDefaultParams({ format, filter, fields })
        );
    },
    exportPDF(orientation, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/pdf",
            { selected },
            Api.handleDefaultParams({ orientation, filter, fields })
        );
    },
    post(data) {
        return Api.postData(endpoint, data);
    },
    put(id, data) {
        return Api.putData(endpoint + "/" + id, data);
    },
    duplicate(id) {
        return Api.putData(endpoint + "/" + id + "/duplicate");
    },
    delete(id) {
        return Api.deleteData(endpoint + "/" + id);
    },
    generateToken() {
        return Api.fetchData(endpoint + "/generate_token");
    },
    provideToken(token) {
        return Api.postData(endpoint + "/provide_token", { new_token: token });
    },
    getFields(id) {
        return Api.fetchData(endpoint + "/" + id + "/fields");
    },
    submitAnswer(token, data) {
        return Api.putData("/public/forms/" + token + "/submit", data);
    },
    getAnswers(answer_id) {
        return Api.fetchData(endpoint + "/" + answer_id + "/answers");
    },
    postAnswer(data) {
        return Api.postData(endpoint + "/answers", data);
    },
    putAnswer(answer_id, data) {
        return Api.putData(endpoint + "/answers/" + answer_id, data);
    },
    deleteAnswer(answer_id) {
        return Api.deleteData(endpoint + "/answers/" + answer_id);
    },
    exportAnswers(
        form_id,
        format,
        filter = null,
        fields = [],
        selected = [],
        exportValues = 0
    ) {
        return Api.putData(
            endpoint + "/" + form_id + "/answers/export",
            { selected },
            Api.handleDefaultParams({ format, filter, fields, exportValues })
        );
    },
    exportAnswersPDF(
        form_id,
        orientation,
        filter = null,
        fields = [],
        selected = []
    ) {
        return Api.putData(
            endpoint + "/" + form_id + "/answers/export/pdf",
            { selected },
            Api.handleDefaultParams({ orientation, filter, fields })
        );
    },
    getTags() {
        return Api.fetchData(endpoint + "/tags");
    }
};
