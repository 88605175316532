import Api from "./Api";

const endpoint = "/vhs";
export default {
    get() {
        return Api.fetchData(endpoint);
    },
    export(format, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/export",
            { selected },
            Api.handleDefaultParams({ format, filter, fields })
        );
    },
    exportPDF(orientation, filter = null, fields = [], selected = []) {
        return Api.putData(
            endpoint + "/pdf",
            { selected },
            Api.handleDefaultParams({ orientation, filter, fields })
        );
    },
    post(data) {
        return Api.postData(endpoint, data);
    },
    put(id, data) {
        return Api.putData(endpoint + "/" + id, data);
    },
    delete(id) {
        return Api.deleteData(endpoint + "/" + id);
    }
};
