const state = {
    rightManagement: {}
};

const getters = {
    getRightsFor: state => key => {
        const rights = state.rightManagement[key];
        if (!rights) {
            return {
                read: false,
                write: false,
                delete: false
            };
        }
        return rights;
    }
};

const mutations = {
    SET_RIGHTS: (state, payload) => {
        if (payload.rights._links) {
            delete payload.rights._links;
        }
        state.rightManagement[payload.key] = payload.rights;
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
