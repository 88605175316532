const state = {
    lang: null,
    size: "sm",
    persistentFilterStoring: false
};

const getters = {
    getSettings: state => state
};

const mutations = {
    SETTINGS: (state, payload) => {
        state = payload;
    },
    CLEAN: (state, payload) => {
        state = Object.assign(state, {
            lang: null,
            size: "sm",
            persistentFilterStoring: false
        });
    }
};

const actions = {
    settings: (context, payload) => {
        context.commit("SETTINGS", payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
