import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/scss/base.scss";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// todo: For performance purposes use explicit icon decleration instead of fetching all FA icons in production
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

moment.locale("de");
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
