<template>
    <div></div>
</template>

<script>
import Toaster from "@/mixins/Toaster/Toaster";
import { mapGetters } from "vuex";

export default {
    name: "Alert",
    mixins: [Toaster],
    computed: {
        ...mapGetters({
            alert: "error/getAlert"
        })
    },
    watch: {
        alert: {
            handler(value) {
                this.makeToast(value.heading, value.text, "danger");
            },
            deep: true
        }
    }
};
</script>
