<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12" class="mv-main pt-4">
                <slot></slot>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "MainContainer"
};
</script>
