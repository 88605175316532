<template>
    <div id="app">
        <template v-if="!isPublic">
            <Alert />
            <Navbar />
            <ChangelogModal v-if="changes" />
        </template>
        <router-view />
    </div>
</template>

<script>
import Alert from "@/components/Alert/Alert";
import Navbar from "@/components/Navbar/Navbar";
import ChangelogModal from "@/components/Changelog/ChangelogModal";
import ApiFactory from "@/api/ApiFactory.js";
import { mapGetters } from "vuex";

const authApi = ApiFactory.get("auth");

export default {
    name: "app",
    components: {
        Alert,
        Navbar,
        ChangelogModal
    },
    created() {
        if (this.isLoggedIn) {
            authApi.get();
        }
    },
    computed: {
        ...mapGetters({
            storedVersion: "version/getVersion",
            isLoggedIn: "auth/isLoggedIn"
        }),
        changes() {
            if (this.isLoggedIn) {
                const prevVersion = this.storedVersion;
                const currentVersion = process.env.VUE_APP_VERSION;
                if (currentVersion != prevVersion) {
                    return true;
                }
            }
            return false;
        },
        isPublic() {
            return this.$route.meta.public ? true : false;
        }
    }
};
</script>
