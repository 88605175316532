import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Home from "../views/Home/Home.vue";

Vue.use(VueRouter);

/** Routes which are not controlled by the backend right management tool */
var unfilteredRoutes = [
    "/",
    "/403",
    "/404",
    "/login",
    "/howto",
    "/changelog",
    "/settings",
    "/forget_password",
    "/forms/p/:token",
    "/datenschutzerklaerung"
];

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login/Login")
    },
    {
        path: "/changelog",
        name: "changelog",
        component: () => import("@/views/Changelog/Changelog"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mitarbeiter",
        name: "mitarbeiter",
        component: () => import("@/views/Mitarbeiter/Mitarbeiter"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/forms",
        name: "forms",
        component: () => import("@/views/Forms/Forms"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/forms/:id",
        name: "forms-detail",
        component: () => import("@/views/Forms/FormsDetail/FormsDetail"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/forms/p/:token",
        name: "forms-presenter",
        component: () => import("@/views/Forms/FormsPresenter/FormsPresenter"),
        meta: {
            requiresAuth: false,
            public: true
        }
    },
    {
        path: "/events",
        name: "events",
        component: () => import("@/views/Events/Events"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/:id",
        name: "events-detail",
        component: () => import("@/views/Events/EventsDetail/EventsDetail"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/locations",
        name: "locations",
        component: () => import("@/views/Locations/Locations"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/persons",
        name: "persons",
        component: () => import("@/views/Persons/Persons"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/exams",
        name: "exams",
        component: () => import("@/views/Exams/Exams"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/exams/deadlines",
        name: "exams-deadlines",
        component: () => import("@/views/Exams/ExamsDeadlines"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/exams/:id",
        name: "exams-detail",
        component: () => import("@/views/Exams/ExamsDetail/ExamsDetail"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/system/user",
        name: "user",
        component: () => import("@/views/User/User"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/datenschutzerklaerung",
        name: "DataPrivacyNotice",
        component: () => import("@/views/DataPrivacyNotice/DataPrivacyNotice"),
        meta: {
            requiresAuth: false,
            public: true
        }
    },
    {
        path: "/403",
        name: "Error403",
        component: () => import("@/views/403/403"),
        meta: {
            // requiresAuth: true
        }
    },
    {
        path: "/404",
        name: "Error404",
        component: () => import("@/views/404/404")
    },
    {
        path: "*",
        redirect: "/404"
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach((to, from, next) => {
    var menu = store.getters["menu/getMenu"];
    var menuFlat = [];
    Object.values(menu).forEach(items => {
        return (menuFlat = menuFlat.concat(items));
    });

    const isLoggedIn = store.getters["auth/isLoggedIn"];

    if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
        next("/login");
    } else if (
        !menuFlat.some(
            item => item.slug === to.path || item.slug === to.matched[0].path
        ) &&
        !unfilteredRoutes.some(
            item => item === to.path || item === to.matched[0].path
        )
    ) {
        next("/403");
    } else {
        store.dispatch("menu/currentPage", to);
        next();
    }
});

export default router;
