/* eslint no-prototype-builtins: off */
import PersonsApi from "@/api/PersonsApi";

const state = {
    data: [],
    busy: {
        data: false,
        mutation: false
    }
};

const getters = {
    getData: state => state.data,
    getSelectData: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.name
            };
        });
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_DATA: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        state.busy = Object.assign(state.busy, payload);
    }
};

const actions = {
    fetchData: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await PersonsApi.duplicateDetection(
            payload.filter,
            payload.query,
            payload.limit,
            payload.page
        );
        if (response.ok) {
            context.commit("SET_DATA", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
