/* eslint no-prototype-builtins: off */
import EventsApi from "@/api/EventsApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false
    }
};

const getters = {
    getData: state => state.data,
    getDataById: state => id => {
        return state.data.filter(item => item.id == id);
    },
    getSelectData: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.name
            };
        });
    },
    getBusy: state => state.busy
};

const mutations = {
    SET_DATA: (state, payload) => {
        state.data = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_DATA: (state, payload) => {
        const data = [...state.data];

        data.push(payload);
        state.data = data;
    },
    DELETE_DATA: (state, payload) => {
        var data = [...state.data];
        data = data.filter(item => item.persons_id != payload);
        state.data = data;
    }
};

const actions = {
    fetchData: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const response = await EventsApi.getParticipants(payload);
        if (response.ok) {
            context.commit("SET_DATA", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    exportExcel: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await EventsApi.exportParticipants(
            payload.id,
            payload.format,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    exportPDF: async (context, payload) => {
        context.commit("BUSY", { pdf: true });
        const response = await EventsApi.exportParticipantsPDF(
            payload.id,
            payload.orientation,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { pdf: false });
    },
    createData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await EventsApi.createParticipant(
            payload.event_id,
            payload.person_id,
            payload.form_answer_id
        );
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_DATA", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteData: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await EventsApi.deleteParticipant(
            payload.event_id,
            payload.person_id
        );
        if (response.ok) {
            context.commit("DELETE_DATA", response.json.data.id);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
