/* eslint no-prototype-builtins: off */
import FormApi from "@/api/FormApi";
import BlobUtil from "@/utils/BlobUtil";

const state = {
    data: [],
    dirty: false,
    busy: {
        data: false,
        export: false,
        excel: false,
        pdf: false,
        mutation: false,
        linkGeneration: false
    }
};

const getters = {
    getForms: state => state.data,
    getForm: state => id => {
        const form = state.data.filter(item => item.id == id);
        return form[0];
    },
    getSelectData: state => {
        return state.data.map(item => {
            return {
                value: item.id,
                text: item.title
            };
        });
    },
    getFormByToken: state => token => {
        const form = state.data.filter(item => item.token == token);
        return form[0];
    },
    getFormName: (state, getters) => id => {
        const form = getters.getForm(id);
        if (form) {
            return form.title;
        } else {
            return "";
        }
    },
    getBusy: state => state.busy,
    getDirty: state => state.dirty
};

const mutations = {
    SET_FORMS: (state, payload) => {
        state.data = payload.map(form => {
            if (form.token) {
                form.form_url = process.env.VUE_APP_FORM_URL + "/" + form.token;
            }
            return form;
        });
    },
    SET_FORM_FIELDS: (state, payload) => {
        const form = state.data.find(item => item.id == payload.form_id);
        if (form) {
            form.fields = payload.fields;
        }
    },
    SET_DIRTY: (state, payload) => {
        state.dirty = payload;
    },
    BUSY: (state, payload) => {
        if (
            ((payload.excel || payload.excel === false) &&
                payload.excel !== state.busy.excel) ||
            ((payload.pdf || payload.pdf === false) &&
                payload.pdf !== state.busy.pdf)
        ) {
            payload.export = !state.busy.export;
        }
        state.busy = Object.assign(state.busy, payload);
    },
    CREATE_FORM: (state, payload) => {
        const data = [...state.data];
        if (payload.token) {
            payload.form_url =
                process.env.VUE_APP_FORM_URL + "/" + payload.token;
        }

        data.push(payload);
        state.data = data;
    },
    EDIT_FORM: (state, payload) => {
        const mutationIndex = state.data.findIndex(
            item => item.id == payload.id
        );
        const data = [...state.data];
        if (mutationIndex != -1) {
            if (payload.token) {
                payload.form_url =
                    process.env.VUE_APP_FORM_URL + "/" + payload.token;
            }
            data[mutationIndex] = payload;
            state.data = data;
        }
    },
    DELETE_FORM: (state, payload) => {
        var data = [...state.data];
        data = data.filter(item => item.id != payload);
        state.data = data;
    }
};

const actions = {
    fetchForms: async (context, payload) => {
        context.commit("BUSY", { data: true });

        const filter = payload && payload.filter ? payload.filter : null;

        const response = await FormApi.get(filter);
        if (response.ok) {
            context.commit("SET_FORMS", response.json.data ?? []);
        }
        context.commit("BUSY", { data: false });
    },
    fetchFormByID: async (context, payload) => {
        context.commit("BUSY", { data: true });

        if (payload == "new") {
            context.commit("SET_FORMS", [
                {
                    id: payload,
                    fields: [],
                    is_active: 0,
                    send_register_confirmation: 0,
                    send_register_return: 0
                }
            ]);
        } else {
            const response = await FormApi.getById(payload);
            if (response.ok) {
                context.commit("SET_FORMS", [response.json.data] ?? []);
            }
        }
        context.commit("BUSY", { data: false });
    },
    exportExcel: async (context, payload) => {
        context.commit("BUSY", { excel: true });
        const response = await FormApi.export(
            payload.format,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { excel: false });
    },
    exportPDF: async (context, payload) => {
        context.commit("BUSY", { pdf: true });
        const response = await FormApi.exportPDF(
            payload.orientation,
            payload.filter,
            payload.selectedFields,
            payload.selectedData
        );
        if (response.ok) {
            BlobUtil.downloadBlob(response.blob, response.filename);
        }
        context.commit("BUSY", { pdf: false });
    },
    createForm: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await FormApi.post(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_FORM", modification);
            answer = modification;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    editForm: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await FormApi.put(payload.id, payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("EDIT_FORM", modification);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    duplicateForm: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = null;
        const response = await FormApi.duplicate(payload);
        if (response.ok) {
            const modification = response.json.data;
            context.commit("CREATE_FORM", modification);
            answer = modification;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteForm: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        var answer = false;
        const response = await FormApi.delete(payload.id);
        if (response.ok) {
            context.commit("DELETE_FORM", payload.id);
            answer = true;
        }
        context.commit("BUSY", { mutation: false });
        return answer;
    },
    deleteMultipleForms: async (context, payload) => {
        context.commit("BUSY", { mutation: true });
        const promises = [];
        for (const id of payload) {
            promises.push(FormApi.delete(id));
        }

        const responseAll = await Promise.all(promises);
        const failedResponses = [];
        for (const response of responseAll) {
            if (!response.ok) {
                failedResponses.push(response);
            } else {
                console.log("delete: ", response.json.data.id);
                context.commit("DELETE_FORM", response.json.data.id);
            }
        }
        context.commit("BUSY", { mutation: false });
        return !(failedResponses.length > 0);
    },
    generateToken: async (context, payload) => {
        context.commit("BUSY", { linkGeneration: true });
        var answer = null;
        const response = await FormApi.generateToken();
        if (response.ok) {
            const url_info = response.json.data;
            answer = url_info.token;
        }
        context.commit("BUSY", { linkGeneration: false });
        return answer;
    },
    provideToken: async (context, payload) => {
        context.commit("BUSY", { linkGeneration: true });
        var answer = false;
        const response = await FormApi.provideToken(payload.new_token);
        if (response.ok) {
            answer = true;
        }
        context.commit("BUSY", { linkGeneration: false });
        return answer;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
