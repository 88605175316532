<template>
    <div class="info-page text-center">
        <b-row>
            <b-col cols="12" offset="0" lg="8" offset-lg="2">
                <header>
                    <img
                        alt="LV VHS Logo"
                        class="logo"
                        src="@/assets/images/logo_height.png"
                        width="250"
                    />
                </header>

                <div class="lead">
                    <b>Herzlich willkommen {{ auth.username }}!</b> Sie haben
                    sich erfolgreich im Verwaltungsprogramm des Landesverbandes
                    der Volkshochulen von NRW e.V. angemeldet.
                </div>

                <hr />
                <div class="">
                    <h4>Schnelleinstieg</h4>
                    <p>
                        Über die Menüleiste gelangen Sie zu den verschiedenen
                        Bereichen des Verwaltungsprogramms.
                        <br />
                        Darüber hinaus stehen Ihnen folgende Direktlinks zur
                        Verfügung:
                    </p>
                    <ul>
                        <li>
                            <b-button
                                variant="link"
                                :href="urls.manual"
                                target="_blank"
                                >Handbuch</b-button
                            >
                        </li>
                        <li>
                            <b-button
                                variant="link"
                                :href="urls.docs"
                                target="_blank"
                                :disabled="true"
                                >Technische Dokumentation</b-button
                            >
                        </li>
                        <li>
                            <b-button
                                variant="link"
                                :href="urls.apiDocs"
                                target="_blank"
                                :disabled="true"
                                >API Dokumentation</b-button
                            >
                        </li>
                        <li>
                            <b-button
                                variant="link"
                                :href="urls.homepage"
                                target="_blank"
                                >Offizielle Webseite</b-button
                            >
                        </li>
                        <li>
                            <b-button
                                variant="link"
                                href="/datenschutzerklaerung"
                                target="_blank"
                                >Datenschutzerklärung</b-button
                            >
                        </li>
                    </ul>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import "./InfoPage.scss";

export default {
    name: "InfoPage",
    computed: {
        ...mapGetters({ auth: "auth/getAuth", urls: "env/getUrls" })
    }
};
</script>
